import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallbackComponent } from './authen/callback/callback.component';

const routes: Routes = [
  { path: 'social', loadChildren: () => import('./socials/socials.module').then(m => m.SocialsModule) },
  { path: 'authen/callback', component: CallbackComponent },
  { path: '**', redirectTo: 'social' }
];

@NgModule({

  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
